'use client';

import Image from 'next/image';
import Link from 'next/link';
import { useState } from 'react';

import { HamburgerMenu, MainLogo, User } from 'assets/icons';
import DesktopMenu from 'components/DesktopMenu/DesktopMenu';
import { HeaderContext } from 'components/Header/context/headerContext';
import MobileMenu from 'components/MobileMenu/MobileMenu';
import useViewport from 'hooks/useViewport';
import { type MenuItemProps } from 'components/MenuItem/DesktopMenuItem';
import { isSelfServiceFeatureFlagEnabled } from 'env';

interface NavbarProps {
  menuHeaderLinks: MenuItemProps[];
}

export default function Navbar({ menuHeaderLinks }: NavbarProps) {
  const { isMobile, isTablet } = useViewport();
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [showSubMenu, setShowSubMenu] = useState<boolean>(false);

  return (
    <HeaderContext.Provider
      value={{
        showSubMenu,
        showMenu,
        handleShowMenu: () => setShowMenu(true),
        handleHideMenu: () => setShowMenu(false),
        handleHideSubMenu: () => setShowSubMenu(false),
        handleShowSubMenu: () => setShowSubMenu(true),
      }}
    >
      <nav className="mx-auto flex max-w-4xl items-end justify-between px-5 py-2.5 md:py-3 lg:px-24 xl:px-10 2xl:px-0">
        <label
          className="md:hidden"
          htmlFor="menu-checkbox"
          onClick={() => {
            setShowMenu((prev) => !prev);
          }}
        >
          <Image alt="Agile Telehealth Menu" src={HamburgerMenu} />
        </label>
        <input
          className="menu-checkbox peer invisible absolute"
          id="menu-checkbox"
          name="menu-checkbox"
          type="checkbox"
        />
        <div className="flex gap-6">
          <Link href="/">
            <Image alt="Agile Telehealth Logo" src={MainLogo} />
          </Link>
          {!isMobile && !isTablet && <DesktopMenu menuHeaderLinks={menuHeaderLinks} />}
        </div>
        <div className="flex items-center gap-6 text-black-800">
          <a
            className="flex"
            href={
              isSelfServiceFeatureFlagEnabled
                ? '/login'
                : 'https://secure.gethealthie.com/go/agiletelehealth'
            }
          >
            <Image alt="User Icon" src={User} />
            <span className="hidden pl-2 text-sm md:block">
              {isSelfServiceFeatureFlagEnabled ? 'Patient Portal' : 'Log in'}
            </span>
          </a>
        </div>
      </nav>
      {(isMobile || isTablet) && <MobileMenu menuHeaderLinks={menuHeaderLinks} />}
    </HeaderContext.Provider>
  );
}
